import React from "react"
import Header from "./header"
import {StyledContainer} from "./styledContainer"

const Layout = ({ location, title, children }) => {
  return (
    <StyledContainer>
      <Header />
      <main id="main-content">{children}</main>
      <footer>
        <p className="back-to-top">
          <a href="#main-content">Back to the top of the page</a>
        </p>
        <p>© {new Date().getFullYear()} You. Yes, You reading this.</p>
        <p>This information is for You. Do whatever You want with it.</p>
      </footer>
    </StyledContainer>
  )
}

export default Layout
