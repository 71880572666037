import styled from '@emotion/styled'
import Dots from "../images/dots.png";

const theme = {
    primaryColor: '#333',
    actionColor: '#fa7d09',
    secondaryColor: '#656',
    spacing: '20px',
    spacingSmall: '10px',
    backgroundColor: '#fefefe',
    backgroundColorLight: '#fff',
    border: '#f4f4f4',
}

export const StyledContainer = styled.div`
    color: ${theme.primaryColor};
    background: ${theme.backgroundColor};
    font-size: 1.8rem;
    font-weight: 400;

    header {
        padding: ${theme.spacingSmall};
        margin: 0;
        text-align: center;

        h1 {
            margin: 0;
            padding: 0;
            line-height: 1;
            font-weight: 200;
            font-size: 3.4rem;
            text-align: center;
            margin: 0 auto;
            padding: 0;
            width: auto;
            display: inline-block;
            background: #fff;
            box-shadow: 0px 5px 8px -1px rgba(0,0,0,0.05);

            span {
                display: block;
            }

            .first-word {
                color: #fff;
                font-weight: 600;
                background: ${theme.primaryColor};
                margin: 0 0 -10px 0;
                padding: 8px 10px 0 10px;
            }

            .second-word {
                color: ${theme.actionColor};
                background: ${theme.primaryColor};
                padding: 3px 3px 8px 3px;
                margin: 10px 0 0 0;
                text-transform: uppercase;
                font-size: 1.6rem;
                font-weight: 400;
                letter-spacing: 0.05rem;
            }

            .third-word {
                font-size: 3rem;
                font-weight: 200;
                color: ${theme.primaryColor};
                border: 1px solid ${theme.border};
                border-top: none;
                padding: 8px 0;
            }
        }
    }

    footer {
        text-align: center;
        padding: 20px;
        margin: 20px 20px 0 20px;
        border-top: 1px solid ${theme.border};
        font-size: 1.3rem;
    }

    main {
        padding: ${theme.spacing} 0;
        background: ${theme.backgroundColor};
    }

    a {
        color: ${theme.actionColor};
        font-weight: 600;
    }

    
    p {
      padding: 0;
      margin: 0 0 15px 0;  
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin: 0 0 10px 0;
            padding: 0 0 0 35px;
            background: url(${Dots}) top left no-repeat;
            background-size: 25px;
        }
    }

    table {
        margin: 60px 0;
        padding: 0;
        background: #fff;
        border: 1px solid ${theme.border};
        border-collapse: collapse;
        box-shadow: 0px 5px 8px -1px rgba(0,0,0,0.03);

        th {
            text-align: center;
            padding: 10px;
            font-weight: 200;
            text-transform: uppercase;
            font-size: 1.3rem;
            color: #fff;
            background: ${theme.primaryColor};
            border-bottom: 1px solid ${theme.border};

            &:first-of-type {
                text-align: left;
            }
        }

        td {
            text-align: center;
            padding: 5px 10px;
            font-family: 'Caveat', cursive;
            font-weight: 700;
            border: 1px solid ${theme.border};
            color: ${theme.secondaryColor};

            &:first-of-type {
                text-align: left;
                font-weight: 400;
                color: ${theme.primaryColor};
            }
        }
    }

    /*** Sections ***/

    section {
        margin: ${theme.spacing};
        border-top: 1px solid ${theme.border};
    }

    .article {
        max-width: 1180px;
        margin: 0 auto;
    }

    .article-section {

        h2 {
            font-size: 2.6rem;
            padding: 0;
            margin: 20px 0;
        }
    }

    .hero-wrapper {
        box-shadow: 0px 5px 8px -1px rgba(0,0,0,0.3);
        margin: 0 0 50px 0;
    }

    .hero {
        font-size: 3rem;
        padding: 50px 0 230px 20px;
        margin: 0;
        background: ${theme.primaryColor};
        color: #fff;
        position: relative;
        overflow: hidden;

        .container {
            max-width: 1300px;
            margin: 0 auto;
        }

        p {
            max-width: 600px;
            margin: 0;
            z-index: 2;
            position: relative;
        }

        img {
            width: 100%;
            max-width: 1000px;
            position: absolute;
            right: -40px;
            bottom: -60px;
        }

        .first-group {
            display: block;
            font-size: 4rem;
            font-weight: 200;

            span {
                color: ${theme.actionColor};
                font-weight: 800;
            }
        }

        .large-font {
            font-size: 3.2rem;
            font-weight: 600;
            color: ${theme.actionColor};

            span {
                color: #fff;
            }
        }

        em {
            color: ${theme.actionColor};
        }
    }

    .secondary-content {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;

        .features.feature-list {
            min-width: 250px;
            margin: 0 ${theme.spacing};
        }

        .featured-content {
            padding: 5px 20px 10px 10px;
        }
    }

    .more-info {
        margin-top: 90px;
        border: 1px solid ${theme.border};
        padding: 10px 20px;
        background: #fff;
    }

    .reference {
        border-top: 1px solid ${theme.border};
        margin-top: 20px;

        h3 {
            padding: 0;
            margin: 20px 0 0 0;
        }

        h4 {
            padding: 5px 10px;
            margin: 30px 0 15px 0;
            display: inline-block;
            background: ${theme.backgroundColor};
            border: 1px solid ${theme.border};
        }

        p {
            margin-left: 10px;
        }
    }

    .results-display {
        display: flex;
        background: #fff;
        padding: 10px;
        margin: 30px 0;
        border: 1px solid ${theme.border};

        .display {
            text-align: center;
            padding: 10px;
            margin: 0 10px;
            width: 50%;

            h4 {
                font-size: 1.8rem;
                margin: 0 0 10px 0;
                padding: 0 0 10px 0;
                border-bottom: 1px solid ${theme.border};
            }
            
            img {
                max-width: 400px;
                width: 100%;
                display: block;
                margin: 0 auto;
            }
        }
    }

    .about-me {
        padding: 10px 20px;

        .profile-pic {
            margin-right: 20px;

            img {
                box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.04);
                border: 1px solid ${theme.border};
            }
        }
        
        .connect {

            img {
                display: inline-block;
                margin: 0px 10px -5px 10px;
            }
        }
    }

    /*** Components ***/

    .example-table {
        margin-top: 60px;
        
        table {
            margin-top: 20px;
        }

        h3 {
            margin: 0;
            padding: 0;
        }
    }
    
    .handwriting {
        font-family: 'Caveat', cursive;
        font-weight: 700;
    }

    .section-break {
        margin: 60px auto;
        display: block;
        opacity: 50%;
        max-width: 304px;
    }

    h2.callout {
        font-weight: 200;
        font-size: 2.2rem;
        background: #fff;
        padding: 8px 20px;
        margin: 25px 0;
        border: 1px solid ${theme.border};
        text-align: center;
        letter-spacing: 0.05rem;
        color: ${theme.secondaryColor};
    }

    h2.action-callout {
        background: ${theme.primaryColor};
        color: ${theme.actionColor};
        letter-spacing: auto;
        font-weight: 600;
    }

    .hashtag {
        font-size: 1.2rem;
        color: ${theme.actionColor};
        opacity: 0.9;
        background: #fff;
        line-height: 1;
        padding: 8px 10px;
        margin: 20px 0 0 0;
        display: inline-block;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.02);
    }

    .feature-list {
        margin: 0 0 0 ${theme.spacing};
        padding: 0;
        box-shadow: 0px 5px 8px -1px rgba(0,0,0,0.03);
        color: ${theme.secondaryColor};
        min-width: 250px;
        background: ${theme.backgroundColorLight};
        border: 1px solid ${theme.border};;

        h2,
        h3 {
            text-transform: uppercase;
            font-size: 1.3rem;
            font-weight: 200;
            padding: 13px 10px 10px 10px;
            margin: 0 0 10px 0;
            letter-spacing: 0.05rem;
            color: ${theme.backgroundColorLight};
            background: ${theme.primaryColor};

            span {
                display: block;
            }
        }

        ul {
            font-weight: 400;
            font-size: 1.5rem;
            margin-top: 15px;
            padding: 0 10px 10px;
        }
    }

    .goal-list {
        padding: 0;
        margin: 40px 0;
        background: ${theme.primaryColor};
        display: flex;
        box-shadow: 0px 5px 8px -1px rgba(0,0,0,0.03);

        li {
            padding: 20px;
            text-align: center;
            width: 33.333%;
            background: ${theme.primaryColor};
            border-right: 1px solid ${theme.secondaryColor};
            font-size: 5rem;
            margin: 0;
            
            &:last-of-type {
                border: none;
            }

            h3 {
                color: ${theme.actionColor};
                font-weight: 600;
                padding: 0;
                margin: 8px 0 0 0;
                font-size: 2.2rem;
            }
        }
    }

    /*** Helpers ***/
    
    .flex {
        display: flex;
    }

    .heavy-font {
        font-weight: 800;
    }

    .medium-font {
        font-weight: 600;
    }

    .light-font {
        font-weight: 200;
    }

    /*** Media Queries ***/

    @media only screen and (max-width: 600px) {

        .hero {
            margin: 0 0 40px 0;
            padding: 20px 0 0 20px;

            p {
                padding-right: 20px;
            }

            img {
                margin: 20px 0 -40px 0;
                position: relative;
                bottom: -10px;
                right: -10px;
            }
        }

        .secondary-content {
            display: block;

            .feature-list.features {
                margin: 20px;
            }

            .featured-content {
                padding: 0 20px 20px;
            }
        }

        .feature-list {
            margin: 20px 0;
        }

        .results-display {
            display: block;

            .display {
                width: 100%;
                margin: 0;
                padding: 0;

                &.left-display {
                    margin: 0 0 30px 0;
                }
            }
        }

        .more-info {
            margin: 10px;
            padding: 10px;
            margin-top: 50px;
        }

        .flex {
            display: block;
        }

        .about-me {

            .flex {
                display: flex;
            }
        }
    }

    @media only screen and (max-width: 440px) {
        
        .goal-list {
            display: block;

            li {
                display: block;
                width: 100%;
                border-bottom: 1px solid #656;
            }
        }

        .about-me {

            .flex {
                display: block;
            }
        }
    }
`;
