import React from "react"

const Header = () => {
  return (
    <header>
        <h1>
            <span className="first-word">Systems </span> 
            <span className="second-word">Over </span> 
            <span className="third-word">Goals </span>
        </h1>
    </header>
  )
}

export default Header
